import React from "react";
import styled from "styled-components";
import { typography, space } from "styled-system";
import { graphql, Link as _Link, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import Layout from "../components/Layout";
import { Title } from "../components/parts/typography";
import { Flex as _Flex, Box } from "../components/FlexBox";

const Link = styled(_Link)`
    display: block;
    text-decoration: none;
    color: #000;
`;

const Flex = styled(_Flex)`
    &::after {
        content: "";
        flex: auto;
    }
`;

const ProjectTitle = styled.span`
    font-size: 15px;
    font-weight: bold;
    display: block;
    ${typography};
    ${space};
`;

const SubTitle = styled.span`
    font-size: 17px;
    display: block;
    text-transform: uppercase;
    font-weight: 500;
    ${typography};
`;

export const ProjekteList = ({ projects }) => {
    return (
        <Flex
            flexDirection="column"
            width="calc(100% + 40px)"
            mx="-20px"
            px="2px"
        >
            <Box px={"18px"}>
                <Title mb={["6px", "calc(29px - 18px)"]}>Unsere Projekte</Title>
            </Box>
            <Flex width="100%" flexWrap="wrap" alignContent="flex-start">
                {projects &&
                    projects.map((item, i) => {
                        let project = item.node.frontmatter;
                        return (
                            project.published && (
                                <Box
                                    key={i}
                                    width="100%"
                                    maxWidth={["100%", "454px"]}
                                    flexBasis={["100%", "50%", "33.33%"]}
                                    p="18px"
                                    order={project.id}
                                >
                                    <Link to={project.path}>
                                        {project.thumbnailImage && (
                                            <Img
                                                fluid={
                                                    project.thumbnailImage
                                                        .childImageSharp.fluid
                                                }
                                            />
                                        )}
                                        <ProjectTitle
                                            fontSize={["16px", "16px"]}
                                            mt={["9px", "12px"]}
                                        >
                                            {project.title}
                                        </ProjectTitle>
                                        <SubTitle fontSize={["15px", "15px"]}>
                                            {project.type}
                                        </SubTitle>
                                    </Link>
                                </Box>
                            )
                        );
                    })}
            </Flex>
        </Flex>
    );
};

const Projekte = () => {
    const { allMarkdownRemark } = useStaticQuery(
        graphql`
            query {
                allMarkdownRemark {
                    edges {
                        node {
                            frontmatter {
                                published
                                id
                                path
                                type
                                title
                                thumbnailImage {
                                    childImageSharp {
                                        fluid(quality: 50, maxWidth: 600) {
                                            ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
    );
    return (
        <Layout contentTopMargin="0">
            <ProjekteList projects={allMarkdownRemark.edges} />
        </Layout>
    );
};

export default Projekte;
